<template>
    <v-container>
        <v-card>
            <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
                <NavButton />
                <h2 class="text-h6 text-uppercase font-weight-bold">Reportería auditoria interna</h2>
            </div>

            <v-divider />

            <div class="px-4 py-4" style="position: relative;">
                <v-expansion-panels class="mt-4" v-model="panel">
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="d-flex align-center" style="gap: 8px;">
                                <v-icon>mdi-filter-settings</v-icon>
                                <span class="text-subtitle-1 font-weight-bold text-uppercase">Filtros</span>
                            </div>
                        </v-expansion-panel-header>
                        <v-divider />
                        <v-expansion-panel-content>
                            <div class="px-4 py-4">
                                <v-row dense align="center">
                                    <v-col cols="12" lg="8">
                                        <v-autocomplete 
                                            v-model="filtros.titulo"
                                            label="Titulo" 
                                            placeholder="Titulo" 
                                            :items="titulos.data"
                                            item-text="nombre"
                                            item-value="id"
                                            outlined 
                                            hide-details
                                            clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <v-autocomplete 
                                            v-model="filtros.horario"
                                            label="Horario" 
                                            placeholder="Horario" 
                                            :items="horarios"
                                            :item-text="getHorarioText"
                                            item-value="valor"
                                            outlined 
                                            hide-details
                                            clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <ProgresiveLoadAutocomplete
                                            v-model="filtros.usuario"
                                            :pageable="usuarios"
                                            :loading="usuarios.isLoading"
                                            :disabled="informacionAuditoria.isLoading"
                                            :transform-item="mapearUsuario"
                                            class="flex-grow-1 flex-shrink-1" 
                                            style="min-width: 200px;"
                                            label="Usuario"
                                            placeholder="Seleccione un Usuario"
                                            itemText="nombre_completo"
                                            itemValue="id"
                                            hide-details
                                            @search="buscarUsuario"
                                            @load-more="cargarMasUsuarios"
                                            clearable
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <v-menu ref="RangoFechas" v-model="RangoFechas" :close-on-content-click="false"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field 
                                                    outlined 
                                                    v-model="dateRangeText"
                                                    label="Desde - Hasta"
                                                    prepend-inner-icon="mdi-calendar" 
                                                    readonly 
                                                    hide-details 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                />
                                            </template>
                                            <vc-date-picker 
                                                title="es" 
                                                is-range
                                                v-model="fechas" 
                                                mode="dateTime"
                                                locale="es"
                                                @input="setFiltrosFechas"
                                                >
                                            </vc-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <div class="d-flex flex-wrap align-center justify-center" style="gap: 8px;">
                                            <v-btn 
                                                class="flex-grow-1 flex-shrink-1" 
                                                :disabled="informacionAuditoria.isLoading"
                                                x-large 
                                                outlined 
                                                color="primary"
                                                @click.stop="limpiarFiltros"
                                            >
                                                Limpiar
                                            </v-btn>
                                            <v-btn 
                                                class="flex-grow-1 flex-shrink-1" 
                                                :loading="informacionAuditoria.isLoading"
                                                x-large 
                                                color="primary"
                                                @click.stop="buscar"
                                            >
                                                Buscar
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>


                <DataTableComponent 
                    :items="informacionAuditoria.data"
                    :headers="headers"
                    :total_registros="totalRegistros"
                    :show_loading="informacionAuditoria.isLoading"
                    v-models:pagina="paginacion.pagina"
                    v-models:select="paginacion.registrosPorPagina"
                    dense
                    no-gutters
                    class="mt-4"
                    @paginar="manejarPaginacion"
                >
                    <template v-slot:item.usuario="{ item }">
                        <b class="mr-1">{{obtenerNombreCompletoPersona(item.Usuario.Empleado.Persona)}}</b>
                    </template>
                    <template v-slot:item.titulo="{ item }">
                        <span>{{ item.titulo.nombre}} </span>
                    </template>
                    <template v-slot:item.fecha_hora="{ item }">
                        <span>{{  moment(item.fecha_hora).format("DD/MM/YYYY hh:mm A") }}</span>
                    </template>
                </DataTableComponent>
            </div>
        </v-card>

    </v-container>
</template>
<script>
import { createPageable, createLoadable, isResponseSuccesful, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import ProgresiveLoadAutocomplete from '@/components/utils/ProgresiveLoadAutocomplete.vue';
import ModalFondosBajaCuantia from './components/bajaCuantia/ModalFondosBajaCuantia.vue';
import EstadisticasAnuales from './components/bajaCuantia/EstadisticasAnuales.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { obtenerNombreCompletoPersona, toMoneyFormat } from '@/utils/data';
import moment from "moment";

export default {
    name: 'reporteAuditoriaInterna',
    components: { NavButton, DataTableComponent, ProgresiveLoadAutocomplete, ModalFondosBajaCuantia, EstadisticasAnuales },
    data: () => ({
        panel: 0,
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
        paginacionUsuario: {
            pagina: 1,
            registrosPorPagina: 30,
        },
        RangoFechas: false,
        fechas: { start: null, end: null },
        filtros: {
            titulo: null,
            usuario: null,
            fecha_desde: null, 
            fecha_hasta: null, 
            horario: null, 
        },
        busquedas: {
            usuario: null,
        },
        filtrosActivos: {
            titulo: null,
            usuario: null,
            fecha_desde: null, 
            fecha_hasta: null, 
        },
        horarios: [
            { valor: true, nombre: 'En horario', horario: '7 AM - 9 PM, L-V' },
            { valor: false, nombre: 'Fuera de horario', horario: 'Fuera de L-V, 7 AM - 9 PM' }
        ],
        informacionAuditoria: createPageable([], 10),
        usuarios: createPageable([], 30),
        titulos: createLoadable([], 10),
    }),
    computed: {
        headers() {
            return [
                { align: 'center', sortable: true, text: 'Usuario', value: 'usuario' },
                { align: 'center', sortable: true, text: 'Fecha', value: 'fecha_hora' },
                { align: 'center', sortable: true, text: 'Titulo', value: 'titulo' },
                { align: 'center', sortable: true, text: 'Descripcion', value: 'descripcion' },
            ];
        },
        dateRangeText() {
            return this.fechas && this.fechas.start && this.fechas.end
                ? moment(this.fechas.start).format("D/M/Y H:mm") +
                " - " +
                moment(this.fechas.end).format("D/M/Y H:mm")
                : "Selecciona un rango de fechas";
        },
        totalRegistros() {
            return this.informacionAuditoria.pagination.total_rows;
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        obtenerNombreCompletoPersona,
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarInformacionAuditoria();
        },
        // DATA
        async cargarInformacionAuditoria() {
            const filtros = {
                pagination: true,
                per_page: this.paginacion.registrosPorPagina,
                page: this.paginacion.pagina,
                id_titulo: this.filtros.titulo,
                id_usuario: this.filtros.usuario,
                fecha_desde: this.filtros.fecha_desde,
                fecha_hasta: this.filtros.fecha_hasta,
                horario: this.filtros.horario
            }

            togglePageable(this.informacionAuditoria);
            const { data, headers } = await this.services.reporteriaAutoriaInterna.obtenerInformacionAuditoria(filtros);
            setPageableResponse(this.informacionAuditoria, data, headers);
        },

        async buscarUsuario(termino) {
            if (this.busquedas.usuario === termino) return;

            this.busquedas.usuario = termino;
            this.usuarios.data = [];
            this.paginacionUsuario.pagina = 1;

            this.cargarUsuarios();
        },

        cargarMasUsuarios() {
            const { page, per_page, total_rows } = this.usuarios.pagination;
            const currentlyLoaded = page * per_page;

            if (!(currentlyLoaded < total_rows)) return;

            this.paginacionUsuario.pagina = page + 1;
            this.paginacionUsuario.registrosPorPagina = per_page;

            this.cargarUsuarios();
        },

        async cargarUsuarios() {
            const filtros = { 
                pagination: true, 
                per_page: this.paginacionUsuario.registrosPorPagina,
                page: this.paginacionUsuario.pagina,
                busqueda: this.busquedas.usuario,
            };

            togglePageable(this.usuarios);
            const { data, headers } = await this.services.reporteriaAutoriaInterna.catalogoUsuario(filtros);   

            const copiaInstituciones = this.usuarios.data;
            setPageableResponse(this.usuarios, data, headers, { skipOnSuccess: true, persistDataOnError: true });
            
            if (!isResponseSuccesful(data)) {
                const { pagina } = this.paginacionUsuario;
                this.paginacionUsuario.pagina = pagina > 1 ? pagina - 1 : 1; 
                return;
            };

            this.usuarios.data = copiaInstituciones.concat(data.data);
        },
        async otenerCatalogoTitulo() {
            toggleLoadable(this.titulos);
            const { data, headers } = await this.services.reporteriaAutoriaInterna.catalogoTitulo();
            setLoadableResponse(this.titulos, data, headers);
        },

        mapearUsuario(usuario) {
            return ({ ...usuario, nombre_completo: this.obtenerNombreCompletoPersona(usuario) });
        },
        setFiltrosFechas() { 
            if (this.fechas && this.fechas.start && this.fechas.end) {
                this.filtros.fecha_desde = this.fechas.start;
                this.filtros.fecha_hasta = this.fechas.end;
            } else {
                this.filtros.fecha_desde = null;
                this.filtros.fecha_hasta = null;
            }
        },

        getHorarioText(item) {
            return `${item.nombre} (${item.horario})`;
        },
        // Botones de filtros
        buscar() {
            this.paginacion.pagina = 1;
            this.filtrosActivos = { ...this.filtros };
            this.filaActiva = null;

            this.cargarInformacionAuditoria();
        },
        limpiarFiltros() {
            this.paginacion.pagina = 1;
            this.fechas = ["",""];
            
            this.filtros = {
                usuario: null,
                fecha_desde: null, 
                fecha_hasta: null,
            }

            this.filtrosActivos = { ...this.filtros };

            this.busquedas = {
                usuario: null,
            }

            this.paginacionUsuario.pagina = 1;

            this.usuarios = createPageable([], 30);
            this.cargarInformacionAuditoria();
            this.cargarUsuarios();
            this.otenerCatalogoTitulo();
        },
    },
    created() {
        this.otenerCatalogoTitulo();
        this.cargarInformacionAuditoria();
        this.cargarUsuarios();
    },
}
</script>
<style scoped>
    :deep(.v-expansion-panel-content__wrap) {
        padding: 0px !important;
    }
</style>